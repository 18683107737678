import React from 'react'
import {
    Box,
} from '@material-ui/core'

import { GoogleMapsContext } from '../context/gmapscontext'
import { UserContext } from '../context/userContext'

export default (props) => {

    const isGoogleMapsLoaded = React.useContext(GoogleMapsContext)
    const { userAddress } = React.useContext(UserContext)

    React.useEffect(() => {
        if (isGoogleMapsLoaded) {
            var pi = { lat: -33.4376397, lng: -70.6158242 };
            var map = new window.google.maps.Map(
                document.getElementById('map'), { zoom: 11, center: pi });
            new window.google.maps.Circle({
                center: pi,
                map: map,
                radius: 5000,
            })
            var icon = {
                url: 'https://storage.googleapis.com/product-gallery/logo.png',
                scaledSize: new window.google.maps.Size(32, 32),
            }
            var pimarker = new window.google.maps.Marker({
                title: 'Pizzería Italia',
                map: map,
                visible: true,
                icon: icon
            })
            pimarker.setPosition(pi)
            if ('fullAddress' in userAddress) {
                console.log('addressDisplay', userAddress)
                var clientMarker = new window.google.maps.Marker({
                    title: 'Pizzería Italia',
                    map: map,
                    visible: true,
                })
                clientMarker.setPosition(userAddress)
            }
        }
    }, [isGoogleMapsLoaded, userAddress])

    return (
        <Box style={{ padding: 0 }}>
            <Box id='map' style={{
                marginTop: 16,
                minHeight: 300,
                width: 500,
            }} />
        </Box>
    )
}