import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#B31917'
        },
        secondary: {
            main: '#48AC33'
        },
        headerContainer: {
            main: '#AEA4A2'
        }
    },
    typography: {
        h3: {
            fontFamily: 'Bellota',
        },
        h6: {
            fontFamily: 'Bellota',
        },
        subtitle1: {
            fontFamily: 'Bellota',
        },
        subtitle2: {
            fontFamily: 'Bellota',
        },
        fontFamily: [
            'Bellota',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',

        ].join(','),
    },
})
export default theme