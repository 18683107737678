import React from 'react'
import {
    Typography,
    Paper,
    Grid,
    Divider,
    makeStyles,
} from '@material-ui/core'
import { ReactComponent as Logo } from '../asset/logo.svg'

const useStyles = makeStyles(theme => ({
    beautySeparator: {
        width: 'auto',
        height: theme.spacing(3),
    },
    orderContainer: {
        padding: theme.spacing(3),
        fontFamily: 'Bellota'
    },
    orderHeaderTitle: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    orderHeaderInfoContainer: {
        textAlign: 'left'
    },
    orderTitle: {
        marginBottom: theme.spacing(2),
    },
    orderDetails: {
        marginTop: theme.spacing(2),
    },
    totalSeparator: {
        padding: theme.spacing(2)
    },
}))
//const calculateTotalAmount = (order) => order.reduce((acc, cur) => acc + cur.total, 0)
const addThousandsSeparator = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export default (props) => {
    const { order } = props
    const classes = useStyles()
    const discount = props.order.order.discount
    const subtotal = order.order.Order_Items.reduce((acc, cur) => acc +
        (cur.Variant.price + cur.Addition_Items.reduce((acc, cur) => acc + cur.price, 0)) *
        cur.quantity, 0)
    const discountAmount = discount ? subtotal * (discount.discount / 100) : 0
    const shippingCost = props.order.order.shippingCost
    const totalAmount = subtotal - discountAmount + shippingCost
    return (
        <Paper>
            <Grid container className={classes.orderContainer}>
                <Grid container className={classes.orderHeaderContainer}>
                    <Grid item align='center' xs={12}>
                        <Logo style={{ height: 100, width: 'auto' }} />
                    </Grid>
                    <Grid container className={classes.orderHeaderInfoContainer}>
                        <Grid item xs={12}>
                            <Typography className={classes.orderHeaderTitle} display='inline'>Orden: </Typography>
                            <Typography display='inline'>{props.order.order.buyOrder}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.orderHeaderTitle} display='inline'>Fecha: </Typography>
                            <Typography display='inline'>{new Date(order.date).toLocaleString('es-CL')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.orderHeaderTitle} display='inline'>Despacho: </Typography>
                            {props.order.order.deliveryType === 'Despacho a Domicilio' ?
                                <Typography display='inline'>{`${props.order.order.deliveryType} (Tiempo de espera entre 40 - 60 minutos) `}</Typography> :
                                <Typography display='inline'>{`${props.order.order.deliveryType} (Tiempo de espera entre 15 - 25 minutos) `}</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.orderHeaderTitle} display='inline'>Instrucciones Adicionales: </Typography>
                            <Typography display='inline'>{props.order.order.additionalInfo}</Typography>
                        </Grid>
                        {props.order.order.deliveryType === 'Despacho a Domicilio' ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.orderHeaderTitle} display='inline'>Dirección: </Typography>
                                    <Typography display='inline'>{props.order.order.address}</Typography>
                                </Grid>

                            </Grid> : null}
                    </Grid>
                </Grid>
                <Grid container className={classes.orderDetails}>
                    <Typography className={classes.orderHeaderTitle} >Detalle:</Typography >
                    {props.order.order.Order_Items.map(item =>
                        <Grid container
                            key={item.SellOrderIdSellOrder}>
                            <Grid item xs={1}>
                                <Typography variant='body1'>
                                    {`x${item.quantity}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant='body1'>
                                    {`${item.Variant.Product.name} - ${item.Variant.name} ${item.modifier}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                <Typography variant='body1' >
                                    {`$${addThousandsSeparator(item.Variant.price)}`}
                                </Typography>
                            </Grid>
                            {item.Addition_Items.map(addition =>
                                <Grid container key={addition.name}>
                                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 8 }}>{` ( + ) `}</Grid>
                                    <Grid item xs={7} > {`${addition.name}`}</Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}> {`$${addThousandsSeparator(addition.price)}`}</Grid>
                                </Grid>
                            )}
                        </Grid>)}
                </Grid>
                <Grid item xs={12} className={classes.totalSeparator}>
                    <Divider />
                </Grid>
                <Grid container justify='flex-end'>
                    <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                        <Typography display='inline' children={`Subtotal: `} />
                        <Typography display='inline' children={`$${addThousandsSeparator(subtotal)}`} />
                    </Grid>
                    {discount ? <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                        <Typography display='inline' children={`${discount.name} ( ${discount.discount}% ): `} />
                        <Typography display='inline' children={`-$${addThousandsSeparator(discountAmount)}`} />
                    </Grid> : null}

                    <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                        <Typography display='inline' children={`Despacho: `} />
                        <Typography display='inline' children={`$${addThousandsSeparator(shippingCost)}`} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                        <Typography display='inline' style={{ fontWeight: 'bold' }} children={`Total: `} />
                        <Typography display='inline' style={{ fontWeight: 'bold' }}>{`$${addThousandsSeparator(totalAmount)}`}</Typography>
                    </Grid>
                </Grid>
                {/*

                <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                    <Typography variant='body1'>{`Despacho: $${addThousandsSeparator(shippingCost)}`}</Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right', alignSelf: 'center' }}>
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>{`Total: $${addThousandsSeparator(totalAmount)}`}</Typography>
                </Grid>*/}
            </Grid>
        </Paper>)

}