import React from 'react';
import './App.css';
import Navbar from './components/navbar'
import NavDrawer from './components/navDrawer'
import Menu from './components/menu'
import Checkout from './components/checkout'
import Voucher from './components/voucher'

import Theme from './themeConfig'
import backgroundImage from './asset/paperBackground.jpg'
import { GoogleMapsProvider } from './context/gmapscontext'
import { UserProvider } from './context/userContext'
import { CatalogProvider } from './context/catalogContext'
import { OrderProvider } from './context/orderContext';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  makeStyles,
  Toolbar,
  ThemeProvider,
} from '@material-ui/core';

const drawerWidth = 240

const styles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    flex: 1,
    minHeight: '100vh',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
  }
}))

function App() {

  const classes = styles()
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false)
  return (
    <ThemeProvider theme={Theme}>
      <OrderProvider>
        <CatalogProvider>
          <GoogleMapsProvider>
            <UserProvider>
              <Router>
                <div className={classes.root}>
                  <Navbar
                    drawerWidth={drawerWidth}
                    onClickMenuButton={() => setIsOpenDrawer(!isOpenDrawer)} />
                  <NavDrawer
                    drawerWidth={drawerWidth}
                    open={isOpenDrawer}
                    onClose={() => setIsOpenDrawer(!isOpenDrawer)}
                  />
                  <div className={classes.content}>
                    <Toolbar />
                    <Switch>
                      <Route exact path='/'>
                        <Menu />
                      </Route>
                      <Route path='/checkout'>
                        <Checkout />
                      </Route>
                      <Route path='/voucher/:token'>
                        <Voucher />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </Router>
            </UserProvider>
          </GoogleMapsProvider>
        </CatalogProvider>
      </OrderProvider>
    </ThemeProvider >

  );
}

export default App;
