//TODO validar el radio de delivery
//TODO Trabajar en eliminar warning de useEffect
import React, { useState, } from "react";
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios'
import { GoogleMapsContext } from '../../context/gmapscontext'
import { UserContext } from '../../context/userContext'

export default (props) => {
    const isGoogleMapsLoaded = React.useContext(GoogleMapsContext)
    const { setUserAddress } = React.useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [searchTimeouts, setSearchTimeouts] = useState([])
    const [predictions, setPredictions] = useState([])
    let service
    let location


    const getPredictions = (query) => {
        setLoading(true)
        if (isGoogleMapsLoaded) {
            if (!location) {
                location = new window.google.maps.LatLng(-33.4376397, -70.6158242)
            }
            else {
                return console.log('variable no asignada')
            }
            if (!service) {
                service = new window.google.maps.places.AutocompleteService()
            } else {
                return console.log('variable no asignada')
            }
        } else {
            return console.log('variable no asignada')
        }
        if (!loading) {
            return
        }
        let newOptions = []
        if (query.length < 2) {
            if (query) newOptions.push({ description: query })
            setPredictions(newOptions)
            return
        }
        const request = {
            input: query,
            location: location,
            radius: 6000,
            types: ['geocode'],
        }
        setSearchTimeouts([...searchTimeouts, setTimeout(() => {
            service.getPlacePredictions(request, (results, status) => {
                console.log(results, status)
                if (query) newOptions.push({ description: query })
                switch (status) {
                    case 'OK':
                        setPredictions([...newOptions, ...results])
                        break
                    case 'INVALID_REQUEST':
                        setPredictions([{ description: 'Dirección Inválida' }])
                        break
                    case 'ZERO_RESULTS':
                        setPredictions([{ description: 'No encontramos la dirección :(' }])
                        break
                    default:
                        console.log(status, results)
                        setPredictions([{ description: 'Ha ocurrido un error en la búsqueda' }])
                        break
                }
                setSearchTimeouts([])
                setLoading(false)
            })
        }, 500)])
    }
    const getPlaceLocation = async options => {
        let geocode
        if (options != null) {
            if (options.place_id) {
                geocode = await axios({
                    method: 'get',
                    url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${options.place_id}&key=AIzaSyCQHRmHf8iN-4ww6c-BV7TaDT0qcbVyZuA`,
                })
            }
            else {
                geocode = await axios({
                    method: 'get',
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(options.description)}&region=cl&key=AIzaSyCQHRmHf8iN-4ww6c-BV7TaDT0qcbVyZuA`,
                })
            }
            setUserAddress({ ...geocode.data.results[0].geometry.location, fullAddress: options.description })
        }
        else {
            setUserAddress({})
        }
    }

    return (
        <Autocomplete
            className={props.className}
            noOptionsText={'Ingresa tu dirección exacta'}
            getOptionLabel={opt => typeof opt === 'object' ? opt.description : opt}
            getOptionSelected={(opt, value) => opt.description === value.description}
            value={props.value}
            options={predictions}
            onChange={(event, newValue, reason) => {
                searchTimeouts.map(to => clearTimeout(to))
                console.log(newValue)
                getPlaceLocation(newValue).then(() => {
                    props.setValue(newValue.description)
                    setLoading(false)
                }
                ).catch(err => console.log(err))

            }}
            onInputChange={(event, newValue) => {
                searchTimeouts.map(to => clearTimeout(to))
                getPredictions(newValue)

            }}
            renderInput={(params) => (
                <TextField {...params}
                    error={props.error}
                    helperText={props.error ? props.error.message : null}
                    label="Dirección de Despacho"
                />
            )}
        />
    )
}